// Import your CSS/SCSS files here:

body {
  font-family: "Raleway", sans-serif;
}
.fixed-background {
  position: relative;
  min-height: 50vh; /* Adjusts the height of the video background */
  max-height: 90vh; /* Adjusts the height of the video background */
}

.content {
  position: relative;
  z-index: 1;
  /* Style your content as needed */
}
.description {
  margin-bottom: 20px;
}
.overlay.gradient-fade-to-left {
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.435) 50%,
    transparent 100%
  );
  width: 30%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-blend-mode: multiply;
}

strong {
  font-weight: 700;
}
.quest {
  font-family: "Questrial", sans-serif;
}
.raleway {
  font-family: "Raleway", sans-serif;
}
h1,
.h1 {
  font-size: responsive 35px 60px !important;
  line-height: responsive 38px 65px !important;
  font-weight: 300;
  font-family: "Raleway", sans-serif;
}
h2,
.h2 {
  font-size: responsive 26px 45px !important;
  line-height: responsive 30px 51px !important;
  font-weight: 100;
  font-family: "Raleway", sans-serif;
}

h3,
.h3 {
  font-size: responsive 16px 20px !important;
  line-height: responsive 20px 25px !important;
  font-family: "Raleway", sans-serif;
}
h4,
.h4 {
  font-size: responsive 20px 35px !important;
  line-height: responsive 25px 40px !important;
  font-family: "Raleway", sans-serif;
}
p,
.p {
  font-size: responsive 13px 19px !important;
  line-height: responsive 17px 24px !important;
  font-weight: 300;
}
.slide-text {
  h2 {
    font-size: responsive 35px 60px !important;
    line-height: responsive 38px 65px !important;
    font-family: "Raleway", sans-serif;
    margin-bottom: 2.5rem;
    font-weight: 700;
  }
  h3 {
    font-size: responsive 25px 35px !important;
    line-height: responsive 28px 41px !important;
    font-family: "Raleway", sans-serif;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
}
.project-block {
  h4 {
    font-size: 24px !important;
  }
}
.form-group,
.mc-field-group {
  position: relative;
  input,
  textarea {
    border: 1px solid #9cb0ba;
    padding: 24px 15px 14px 15px;
    width: 100%;
    border-radius: 0.5rem !important;
    @media (max-width: 720px) {
      padding: 15px 10px 14px 15px;
      border-radius: 0.3rem !important;
    }
  }
  span {
    position: absolute;
    top: 8px;
    left: 15px;
    color: #3f3d56;
    font-size: 11px;
    z-index: 10;
    font-weight: bold;
  }
}
.parent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  @media (max-width: 880px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}

.div1 {
  grid-area: 1 / 1 / 4 / 3;
  // max-height: 244px;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div2 {
  grid-area: 1 / 3 / 6 / 5;
  // max-height: 425px;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div3 {
  grid-area: 1 / 5 / 5 / 7;
  // max-height: 287px;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div4 {
  grid-area: 4 / 1 / 9 / 3;
  // max-height: 311px;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div5 {
  grid-area: 6 / 3 / 10 / 5;
  // max-height: 322px;
  @media (max-width: 880px) {
    width: 100%;
  }
}
.div6 {
  grid-area: 5 / 5 / 9 / 7;
  // max-height: 338px;
  @media (max-width: 880px) {
    width: 100%;
    .img-bg {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
}
.grid-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid-col-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
/* Custom Grid CSS */
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

@media (max-width: 768px) {
  .grid-cols-2,
  .grid-cols-3,
  .grid-cols-4,
  .grid-cols-5 {
    grid-template-columns: repeat(
      2,
      minmax(0, 1fr)
    ); /* Adjust to 2 columns on smaller screens */
  }
}

.news-wrapper {
  p {
    margin-bottom: 1rem;
  }
  ul {
    margin-left: 1rem;
    li {
      list-style: circle;
      margin-bottom: 1rem;
    }
  }
}
.two-col-split {
  @media (min-width: 1024px) {
    -webkit-columns: 100px 2;
    /* Chrome, Safari, Opera */
    -moz-columns: 120px 2;
    /* Firefox */
    columns: 120px 2;
  }
  p {
    margin-bottom: 1rem;
  }
}
.text-block {
  h2 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
}
.blogPostList__pagination__prev {
  width: 80px;
  height: 80px;
  display: inline-block;
  background: url("/images/grey-arrow-left.svg");
}
.blogPostList__pagination__pageNumber {
  font-size: 18px;
  padding: 0 10px;
  color: #c44615;
}

.pageNumberIsActive {
  color: #000;
}
.blogPostList__pagination__next {
  width: 80px;
  height: 80px;
  display: inline-block;
  background: url("/images/grey-arrow-right.svg");
}
.content__gdpr {
  input {
    width: auto;
  }
  fieldset.mc_fieldset.gdprRequired.mc-field-group {
    border: 1px solid #9cb0ba;
    padding: 24px 15px 14px 15px;
    width: 100%;
    border-radius: 0.5rem !important;
  }
  p {
    font-size: 14px;
    margin-bottom: 1rem;
  }
}
.content__gdprLegal {
  p {
    font-size: 14px;
    margin-bottom: 1rem;
  }
}
#ticker-widget {
  width: 100%;
  border: none;
  overflow: hidden;
}
#header-widget {
  width: 100%;
  border: none;
  overflow: hidden;
  max-height: 30px;
  font-size: 12px;
  span {
    font-size: 12px;
  }
}
.news-wrapper {
  a {
    color: #0f56d4;
    border-bottom: 1px solid;
  }
}

.fade-in-sub {
  animation: forwards 0.3s;
  animation-name: fade-in;
  animation-delay: 3s;
  opacity: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fulltext-block {
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 10px;
  }
}

.media-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  object-fit: cover;
  justify-content: center;
  align-items: center;
}
.asterix {
  left: -8px !important;
  top: 0 !important;
  font-size: 14px !important;
}
